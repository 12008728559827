.container{
  width: 100%;
  height: 80vh !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner-container{
  width: 100%;
  height: 100%;
  display: block;
  align-items: center;
  text-align: center;
  overflow: auto;
}

.inner-container li{
  padding: 5px;
  margin-top: 10px;
}

.inner-container h2{
  margin-top: 20px;
  text-align: center;
}

*{
  margin: 0;
  padding: 0;
}

body{
  color: white;
}

.container{
  margin: 10px;
  padding : 10px;
  display: flex;
  justify-content: center;
  width: auto;
  height: 70vh;
}

.login-form{
  color: white;
  height: 100%;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input, button{
  display: block;
  margin: 0 auto;
  margin-top: 13px;
  font-size: 1rem;
  width: 300px;
  height: 40px;
  text-align: center;
  border: 1px solid black;
  border-radius: 0.3rem;
}

button{
  color: white;
  background-color: blue;
}

h1{
  margin-bottom: 15px;
}

.verified{
  margin-top: 10px;
}

.verified p{
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  font-size: larger;
}

.verified img{
  margin-top: 20px;
  width: 300px;
  height: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}